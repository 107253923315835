import { computed, reactive } from 'vue'
import { useStore, useMoney } from 'skid-composables'
import PromoterCampaignShareShip from '../../../../shared/resource_models/promoter_campaign_share_ship.js'

export default ({ promoterShare, options = {} }) => {
  const store = useStore()
  const money = useMoney()

  const categoryIndexes = {
    main: 3,
    event: 5
  }
  const data = reactive({
    scope: null,
    promoterShare: promoterShare,
    joinedCampaignIds: [],
    campaignIds: [],
    meta: {},
    campaignShareShipIds: [],
    categoryIndexes: [categoryIndexes[options.category || 'main']],
    queryOptions: reactive({
      search: options.search,
      filter: options.filter,
      sort: options.sort,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize
    }),
    isLoaded: false
  })

  const promoterLevel = computed(() => {
    if (!data.promoterShare) return null

    return store.getters['promoterLevels/find'](promoterShare.level_id)
  })

  const setScope = (scope) => {
    data.scope = scope
  }

  const setPromoterShare = (promoterShare) => {
    data.promoterShare = promoterShare
  }

  const setCategories = (categories) => {
    data.categoryIndexes = categories.map(
      (category) => categoryIndexes[category]
    )
  }

  const promoterEvent = computed(() => {
    return store.getters['promoterEvents/kolcenter']
  })

  const fetchOpenMainCampaigns = async () => {
    const response = await store.dispatch('promoterEvents/fetchCampaigns', {
      model: promoterEvent.value,
      options: {
        sort: 'started_at',
        filter: data.queryOptions.filter || 'open',
        search: Object.assign(
          {
            category_in: data.categoryIndexes
          },
          data.queryOptions.search
        ),
        pageNumber: data.queryOptions.pageNumber,
        pageSize: data.queryOptions.pageSize
      }
    })

    // to-do 檔期是已報名與可報名的聯集，如果還要加上 pagination，則只能依賴後端完成，目前方法並未考慮 pagination
    if (data.scope === 'promoter_member') {
      response.data.data.forEach((record) => {
        if (!data.campaignIds.includes(record.id))
          data.campaignIds.push(record.id)
      })
    } else {
      data.campaignIds = response.data.data.map((row) => row.id)
    }

    data.meta = response.data.meta
  }

  const fetchCampaignShareShips = async () => {
    const response = await store.dispatch(
      'promoterShares/fetchCampaignShareShips',
      {
        model: data.promoterShare,
        options: {}
      }
    )

    data.campaignShareShipIds = response.data.data.map((record) => {
      return record.id
    })
  }

  const fetchJoinedMainCampaigns = async () => {
    const response = await store.dispatch('promoterEvents/fetchCampaigns', {
      model: promoterEvent.value,
      options: {
        sort: '-created_at',
        search: Object.assign(
          {
            category_eq: data.category,
            campaign_share_ships_share_id_eq: data.promoterShare.id,
            campaign_share_ship_state_eq: 'joined'
          },
          data.queryOptions.search
        )
      }
    })

    response.data.data.forEach((record) => {
      // data.campaignIds.push(record.id)
      data.joinedCampaignIds.push(record.id)
    })
  }

  const fetchPromoterEventProductShips = async () => {
    if (data.campaignIds.length === 0) return

    const response = await store.dispatch(
      'promoterEvents/fetchEventProductShips',
      {
        model: promoterEvent.value,
        options: {
          search: {
            campaign_id_in: data.campaignIds
          }
        }
      }
    )
  }

  const promoterCampaigns = computed(() => {
    return data.campaignIds
      .map((campaignId) => {
        return store.getters['promoterCampaigns/find'](campaignId)
      })
      .sort((a, b) => {
        const mapKey = {
          registration: 1,
          for_sale: 2,
          will_open: 3,
          not_open: 4
        }
        const rankA = data.joinedCampaignIds.includes(a.id)
          ? 0
          : mapKey[a.progress()]
        const rankB = data.joinedCampaignIds.includes(b.id)
          ? 0
          : mapKey[b.progress()]
        return rankA - rankB
      })
  })

  const meta = computed(() => {
    return data.meta
  })

  const partners = computed(() => {
    return promoterCampaigns.value.map((campaign) => {
      return store.getters['partners/find'](campaign.partner_id)
    })
  })

  const fetchBrands = () => {
    if (partners.value.length === 0) return

    return store.dispatch('brands/all', {
      search: {
        partner_id_in: partners.value.map((partner) => partner.id)
      }
    })
  }

  const pageChangeHandler = (page) => {
    data.queryOptions.pageNumber = page

    fetchData()
  }

  const fetchData = async () => {
    data.isLoaded = false
    if (data.scope === 'promoter_member') {
      await fetchJoinedMainCampaigns()
      await fetchOpenMainCampaigns()
      await fetchCampaignShareShips()
      await fetchPromoterEventProductShips()
    } else {
      await fetchOpenMainCampaigns()
      await fetchPromoterEventProductShips()
    }

    await fetchBrands()
    data.isLoaded = true
  }

  return {
    queryOptions: data.queryOptions,

    pageChangeHandler,
    fetchData,

    setCategories,
    setScope,
    setPromoterShare,

    promoterCampaigns,
    meta,

    isLoading: computed(() => {
      return !data.isLoaded
    })
  }
}
