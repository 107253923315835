<template lang="pug">
.vc-kolcenter-campaign-guest-period-list
  .title
    | {{ promoterCampaign.name }}
  .subtitle
    template(v-if="promoterCampaign.category === 'event'")
      | 活動時段
    template(v-else)
      | 帶貨時段
  .content
    .items
      .item(
        v-for="(promoterCampaignPeriod, index) in registrablePromoterCampaignPeriods"
        :key="promoterCampaignPeriod.id"
      )
        sales-time-range-view.mb-2(
          :promoter-campaign-period="promoterCampaignPeriod"
          display-type="oneline"
          include-name
          name-type="is-ci"
        )
  .action
    button.button.is-shadow.is-ci-blue(@click="clickHandler")
      | 報名
</template>

<script setup lang="ts">
import { reactive, computed, onMounted } from 'vue'
import SalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/sales-time-range-view.vue'

import {
  useToast,
  useStore,
  useInstance,
  useModal,
} from 'skid-composables'

const props = defineProps({
  promoterCampaign: { type: Object, required: true }
})

const emit = defineEmits(['show-registration'])

const vueInstance = useInstance()
const store = useStore()
const data = reactive({
  promoterCampaignPeriodIds: [],
  periodIds: []
})

const promoterCampaignPeriods = computed(() => {
  return data.promoterCampaignPeriodIds.map((id) => {
    return store.getters['promoterCampaignPeriods/find'](id)
  })
})

const registrablePromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value
    .filter((promoterCampaignPeriod) => {
      return promoterCampaignPeriod.isOnRegistration()
    })
    .sort((a, b) => a.started_at - b.started_at)
})

const fetchPeriods = async () => {
  const response = await store.dispatch('promoterCampaigns/fetchPeriods', {
    model: props.promoterCampaign
  })

  data.promoterCampaignPeriodIds = response.data.data.map((row) => row.id)
}

const clickHandler = () => {
  emit('show-registration')

  vueInstance.$parent.close()
}

onMounted(() => {
  fetchPeriods()
})

</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.vc-kolcenter-campaign-guest-period-list
  +utils.is-flat-card
  padding: size.$gap

  .title
    font-size: font.$size-5
    margin-bottom: size.$gap
  .content
    padding-bottom: 2rem
  .action
    display: flex
    justify-content: space-between
    margin: 0 size.$gap * -0.5
    .button
      width: 100%
      margin: 0 size.$gap * 0.5
      text-align: center
</style>
