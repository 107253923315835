<template lang="pug">
k-button.register-button(
  type="ci-blue"
  @click="clickHandler"
)
  .icon
    i.mdi.mdi-plus
  span {{ title }}
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useModal, useInstance, useI18n } from 'skid-composables'
import Confirmation from '../../common/confirmation.vue'
import GuestPeriodList from './guest-period-list.vue'
import useCampaignShowLink from '../../../composables/use_campaign_show_link'
import kButton from '@sharedComponents/common/k-button.vue'

export default defineComponent({
  components: {
    kButton
  },

  props: {
    promoterCampaign: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const vueInstance = useInstance()
    const i18n = useI18n()

    const clickHandler = () => {
      if (props.promoterCampaign.has_periods) {
        showPeriodList()
      } else {
        showRegisteration()
      }
    }

    const showPeriodList = () => {
      useModal({
        parent: vueInstance,
        component: GuestPeriodList,
        width: '500px',
        props: {
          promoterCampaign: props.promoterCampaign
        },
        events: {
          'show-registration': () => {
            showRegisteration()
          }
        }
      })
    }

    const showRegisteration = async () => {
      await confirmation({
        title: '您尚未登入，請先完成註冊或登入',
        confirmButtonText: '註冊 / 登入',
        cancelButtonText: '稍後再登入'
      })

      const backUrl = `${useCampaignShowLink(
        props.promoterCampaign
      )}?register=1`

      window.location.href = `/promoters/login?back_url=${backUrl}`
    }

    const confirmation = ({ title, confirmButtonText, cancelButtonText }) => {
      return new Promise((resolve, reject) => {
        useModal({
          parent: vueInstance,
          component: Confirmation,
          width: '500px',
          props: {
            content: title,
            confirmText: confirmButtonText,
            cancelText: cancelButtonText
          },
          events: {
            confirm: () => {
              resolve()
            },
            cancel: () => { }
          }
        })
      })
    }

    const title = computed(() => {
      if (props.promoterCampaign.has_periods) {
        if (props.promoterCampaign.category === 'event') {
          return '查看活動時段'
        } else {
          return '查看帶貨時段'
        }
      }

      return '報名'
    })

    return {
      clickHandler,
      title
    }
  }
})
</script>
