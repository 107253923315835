<template lang="pug">
.vc-shared-product-swipe-card
  .inner(@click="clickHandler")
    video.video(
      v-if="hasVideo"
      loop
      playsinline
      :src="data.video.url"
    )
    img.image(
      v-else
      :src="data.cover.url"
    )
    .info-overlay
      h3.title
        | {{ data.name }}

    .video-controls(v-if="hasVideo")
      button.volumn-button(@click="videoVolumnButtonHandler")
        i.fa.fa-volume-up
        i.fa.fa-volume-off
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  data: {
    id: String
    cover: {
      url: string
    }
    video: {
      url: string
    }
    name: string
  }
}>()

const emit = defineEmits(['click-overlay'])

// computed
/**
 * @returns {ComputedRef<boolean>}
 */
const hasVideo = computed(() => {
  return !!props.data.video?.url
})

// methods
/**
 * @returns {void}
 */
const clickHandler = (): void => {
  emit('click-overlay', { data: props.data })
}

/**
 * @returns {void}
 */
const videoVolumnButtonHandler = (): void => {
  const currentVideo = document.querySelector('.current-video')
  const volumeControl = document.querySelector('.volumn-button')

  volumeControl.classList.toggle('muted')
  if (volumeControl.classList.contains('muted')) {
    currentVideo.muted = true
  } else {
    currentVideo.muted = false
  }
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.vc-shared-product-swipe-card
  height: 100%
  border-radius: size.$gap * 3
  overflow: hidden
  box-shadow: 0 0 size.$gap rgba(color.$black, .2)
  background-color: color.$white
  position: relative
  .inner
    height: 100%
    display: grid
    grid-template-rows: 1fr auto
    border-radius: size.$gap * 3
    overflow: hidden
    position: relative
    transform: translate3d(0, 0, 0)
  .image,
  .video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    display: grid
    justify-content: center
    align-content: center
  .info-overlay
    $swiper-card-options-height: 124px

    height: 100%
    padding: size.$gap
    padding-bottom: $swiper-card-options-height
    background-repeat: repeat-x
    grid-row: 2 / 3
    &:after
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-image: linear-gradient(180deg, rgba(color.$black, 0) 50%, rgba(color.$black, 0.8) 100%)
      z-index: 1

    > .title
      color: color.$white
      font-size: font.$size-5
      font-weight: font.$semibold
      position: relative
      z-index: 2

  .video-controls
    display: flex
    padding: size.$gap
    justify-content: flex-end
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 2000

    .volumn-button
      border: 0
      padding: 0
      background: transparent
      outline: none
      cursor: pointer
      width: 20px
      display: flex
      align-items: center
      justify-content: center
      &:hover
        i
          color: color.$white

      i
        font-size: font.$size-5
        color: color.$white
      > .fa-volume-up
        display: inline-flex

      > .fa-volume-off
        display: none

      &.muted
        > .fa-volume-up
          display: none

        > .fa-volume-off
          display: inline-flex
</style>
