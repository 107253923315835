<template lang="pug">
k-form-container.vc-kolcenter-promoter-campaign-campaign-action-button-register-form-main-form(
  style-type="kolcenter-modal-box"
  :loading="!data.isDataInited"
)
  template(#header)
    .title {{ `報名參加${promoterCampaign.name}` }}

  template(slot="body")
    .section(v-if="!promoterCampaign.has_periods")
      .section-title {{ attributeLocaleText('promoter/campaign', 'sales_periods') }}
      promoter-campaign-sales-time-range-view(
        :promoter-campaign="promoterCampaign"
        display-type="oneline"
      )
      .help.is-danger(v-if="errors.get('limit')")
        | {{ errors.get('limit').join('') }}

    .section(v-if="promoterCampaign.has_periods")
      .section-title
        | {{ promoterCampaign.registerText() }}
        | (可複選)
      k-field(
        style-type="kolcenter"
        :type="errors.errorClassAt('campaign_period_ids')"
        :message="errors.get('campaign_period_ids')"
      )
        promoter-campaign-period-sales-time-range-view.mb-3(
          v-for="(promoterCampaignPeriod, index) in registrablePromoterCampaignPeriods"
          :key="promoterCampaignPeriod.id"
          :promoter-campaign-period="promoterCampaignPeriod"
          v-model="form.campaign_period_ids"
          display-type="oneline"
          include-name
          include-checkbox
          name-type="is-ci"
          @input="errors.clear('campaign_period_ids')"
        )
      .help.is-danger(v-if="errors.get('limit')")
        | {{ errors.get('limit').join('') }}

    .section(v-if="promoterCampaign.has_sample")
      .section-title 樣品寄送地址
      p.note.mb-2 當您審核通過，將提供樣品給您，請填寫您的樣品取貨地址
      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/campaign_sample_shipment', 'receiver_name')"
        :type="errors.errorClassAt('receiver_name')"
        :message="errors.get('receiver_name')"
        :options="{ bordered: true }"
        horizontal
      )
        k-input(
          style-type="kolcenter"
          type="text"
          v-model="form.receiver_name"
          data-behavior="sample-shipment-receiver_name"
          @input="errors.clear('receiver_name')"
        )

      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/campaign_sample_shipment', 'contact_phone')"
        :type="errors.errorClassAt('contact_phone')"
        :message="errors.get('contact_phone')"
        horizontal
      )
        k-input(
          style-type="kolcenter"
          type="text"
          placeholder="e.g. 09xxxxxxxx"
          v-model="form.contact_phone"
          data-behavior="promoter-sample_contact_phone"
          @input="errors.clear('contact_phone')"
        )
      k-field(
        style-type="kolcenter"
        grouped
        label="縣市區域"
        horizontal
      )
        k-field(
          style-type="kolcenter"
          :type="errors.errorClassAt('sample_address_city')"
          :message="errors.get('sample_address_city')"
        )
          k-city-select(
            v-model="form.sample_address.city"
            @input="errors.clear('sample_address_city')"
            style-type="kolcenter"
          )

        k-field(
          style-type="kolcenter"
          :type="errors.errorClassAt('sample_address_area')"
          :message="errors.get('sample_address_area')"
        )
          k-area-select(
            :city="form.sample_address.city"
            :area.sync="form.sample_address.area"
            :zip.sync="form.sample_address.zip"
            @update:area="errors.clear('sample_address_area')"
            style-type="kolcenter"
          )

      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/bank_account_info', 'contact_street_address')"
        :type="errors.errorClassAt('sample_address_street_address')"
        :message="errors.get('sample_address_street_address')"
        :options="{ bordered: true }"
        horizontal
      )
        k-input(
          style-type="kolcenter"
          type="text"
          placeholder="e.g. 基隆路xx號"
          v-model="form.sample_address.street_address"
          data-behavior="promoter-sample_address"
          @input="errors.clear('sample_address_street_address')"
        )

      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/campaign_sample_shipment', 'receiver_note')"
        :type="errors.errorClassAt('receiver_note')"
        :message="errors.get('receiver_note')"
        :options="{ bordered: true }"
        horizontal
      )
        k-input(
          style-type="kolcenter"
          type="text"
          placeholder="例如：請放管理室、1/1出國1/6後再配送...等特別說明。"
          v-model="form.receiver_note"
          data-behavior="promoter-sample_receiver_note"
          @input="errors.clear('receiver_note')"
        )
      label.remember-sample-address
        k-checkbox(v-model="form.remember_sample_address")
        span {{ attributeLocaleText('promoter/campaign_share_ship', 'remember_sample_address') }}

    .section
      .agreement-info
        k-checkbox(v-model="data.isAgreed")
        a.link(@click="showAgreementView") 我同意合作推廣協議

    b-modal(:active.sync="data.isAgreementViewActive")
      agreement-view

  template(slot="footer")
    k-button(
      type="shadow"
      :loading="isLoading"
      @click="cancel"
    )
      | 稍後再確認
    k-button(
      type="ci-blue"
      :loading="isLoading"
      :disabled="!data.isAgreed"
      @click="submit"
    )
      | 確認報名
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useStore, useInstance } from 'skid-composables'

import PromoterCampaignSalesTimeRangeView from '@sharedComponents/promoter_campaign/common/sales-time-range-view.vue'
import PromoterCampaignPeriodSalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/sales-time-range-view.vue'
import kFormContainer from '@sharedComponents/common/k-form-container.vue'
import KField from '@sharedComponents/common/k-field.vue'
import KInput from '@sharedComponents/common/k-input.vue'
import KCitySelect from '@sharedComponents/common/k-city-select.vue'
import KAreaSelect from '@sharedComponents/common/k-area-select.vue'
import KCheckbox from '@sharedComponents/common/k-checkbox.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import Form from 'odd-form_object'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship.js'
import kLoading from '@kolcenterComponents/common/k-loading.vue'
import agreementView from './main_form/agreement-view.vue'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true }
})

const emit = defineEmits(['cancel', 'data-saved'])

const store = useStore()
const data = reactive({
  isDataInited: false,
  promoterCampaignPeriodIds: [],
  isAgreementViewActive: false,
  isAgreed: false
})
const form = reactive(
  new Form(
    new PromoterCampaignShareShip({
      campaign_period_ids: [],
      contact_phone: '',
      sample_address: {
        city: '',
        area: '',
        zip: '',
        street_address: ''
      },
      receiver_note: '',
      remember_sample_address: false
    })
  )
)

const initSampleAddress = () => {
  const profile = promoterMember.value.profile

  if (!profile.sample_address.city) {
    form.remember_sample_address = true
    return
  }

  form.sample_address.city = profile.sample_address.city
  form.sample_address.area = profile.sample_address.area
  form.sample_address.zip = profile.sample_address.zip
  form.sample_address.street_address = profile.sample_address.street_address
}

const errors = computed(() => form.model.errors)

const isLoading = computed(() => {
  return store.getters['promoterCampaignShareShips/isLoading']
})

const promoterMember = computed(() => {
  const promoter = store.getters['promoters/find'](
    props.promoterShare.promoter_id
  )

  return store.getters['promoterMembers/find'](promoter.promoter_member_id)
})

const promoterCampaignPeriods = computed(() => {
  return data.promoterCampaignPeriodIds.map((id) => {
    return store.getters['promoterCampaignPeriods/find'](id)
  })
})

const registrablePromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value
    .filter((promoterCampaignPeriod) => {
      return promoterCampaignPeriod.isOnRegistration()
    })
    .sort((a, b) => a.started_at - b.started_at)
})

const fetchPeriods = async () => {
  const response = await store.dispatch('promoterCampaigns/fetchPeriods', {
    model: props.promoterCampaign
  })

  data.promoterCampaignPeriodIds = response.data.data.map((row) => row.id)
}

const cancel = () => {
  emit('cancel')
}

const submit = async () => {
  await store.dispatch('promoterCampaignShareShips/register', {
    share_id: props.promoterShare.id,
    campaign_id: props.promoterCampaign.id,
    model: form.sync()
  })

  emit('data-saved')
}

function showAgreementView() {
  data.isAgreementViewActive = true
}

onMounted(async () => {
  form.campaign_period_ids = []
  form.contact_phone = promoterMember.value.profile.contact_phone
  form.receiver_name = promoterMember.value.profile.real_name

  await initSampleAddress()

  await fetchPeriods()

  data.isDataInited = true
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'

.vc-kolcenter-promoter-campaign-campaign-action-button-register-form-main-form
  .remember-sample-address
    display: flex
    align-items: center
    margin-bottom: .5rem

  .agreement-info
    display: flex
    align-items: center
    .link
      color: color.$ci
</style>
